.error-page .text-huge {
    font-size: 7em;
}

.error-page svg {
    width: 100%;
    visibility: hidden;
}

@media screen and (max-width: 768px) {
    .error-page .container {
        margin-top: 70px;
        margin-bottom: 70px;
    }
}
